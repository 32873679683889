<template>
  <div id="app">
    <div class="content">
      <Banner></Banner>
      <Info></Info>
      <Rotate
        @open-rule="openRule"
        @open-award="openAward"
        :deg="deg"
      ></Rotate>
      <Promotion></Promotion>
    </div>
    <StickyButton></StickyButton>
    <transition name="normal">
      <Rule
        v-if="isRuleOpen"
        @close-rule="closeRule"
      ></Rule>
    </transition>
    <transition :name="isDelay">
      <Award
        v-if="isAwardOpen"
        @close-award="closeAward"
        :award="getAward"
      ></Award>
    </transition>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Info from '../components/Info.vue'
import Rotate from '../components/Rotate.vue'
import Promotion from '../components/Promotion.vue'
import StickyButton from '../components/StickyButton.vue'
import Rule from '../components/Rule.vue'
import Award from '../components/Award.vue'
export default {
  components: {
    Banner,
    Info,
    Rotate,
    Promotion,
    StickyButton,
    Rule,
    Award
  },
  data () {
    return {
      isRuleOpen: false,
      isAwardOpen: false,
      isLogin: false,
      isDelay: 'delay',
      SSID: '',
      getAward: {},
      deg: ''
    }
  },
  methods: {
    openRule () {
      this.isRuleOpen = true
    },
    closeRule () {
      this.isRuleOpen = false
    },
    openAward () {
      if (!this.isLogin) {
        document.location.href = `${process.env.VUE_APP_API}/login.php`
      } else {
        var userFormData = new FormData()
        userFormData.append('SSID', this.SSID)
        const api = `${process.env.VUE_APP_API}/get_price.php`
        this.$http.post(api, userFormData)
          .then(res => {
            if (res.data.isSuccess === 1) {
              if (res.data.value.award_id === '18') {
                this.deg = 1817
              }
              if (res.data.value.award_id === '19') {
                this.deg = 1853
              }
              if (res.data.value.award_id === '20') {
                this.deg = 1889
              }
              if (res.data.value.award_id === '21') {
                this.deg = 1923
              }
              if (res.data.value.award_id === '22') {
                this.deg = 1961
              }
              this.getAward = res.data.value
              this.isAwardOpen = true
            } else {
              alert(res.data.message)
            }
          })
      }
    },
    closeAward () {
      this.isAwardOpen = false
      this.isDelay = 'normal'
    },
    getID () {
      const tokenID = this.$route.query.SSID
      if (tokenID !== undefined) {
        document.cookie = `lineSlotTokenID=${tokenID}`
        this.$router.push('/')
      }
    },
    checkLogin () {
      const tokenCookie = document.cookie.replace(/(?:(?:^|.*;\s*)lineSlotTokenID\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (tokenCookie !== '') {
        this.isLogin = true
        this.SSID = tokenCookie
      }
    },
    logout () {
      document.cookie = 'lineSlotTokenID=;expires=Thu, 18 Dec 2043 12:00:00 GMT'
    }
  },
  mounted () {
    this.getID()
    this.checkLogin()
    window.addEventListener('beforeunload', e => this.logout(e))
  },
  unmounted () {
    window.removeEventListener('beforeunload', e => this.logout(e))
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
  :root {
    --gradient: linear-gradient(180deg, #3667BF 0%, #5048B7 100%);
    --reverse-gradient: linear-gradient(0deg, #3667BF 0%, #5048B7 100%);
    --deepPurple: #5048B7;
    --lightPurple: #3667BF;
    --yellow: #FFE255;
    --red: #F26666;
  }
  * {
    box-sizing: border-box;
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  body {
    margin: 0;
    padding: 0;
    color: #333;
    font-family: 'Noto Sans TC', sans-serif;
    background: var(--lightPurple);
  }
  .wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .content {
    overflow: hidden;
    background: var(--deepPurple);
  }
  .title {
    margin-bottom: 25px;
    font-size: 1.75em;
    font-weight: 700;
  }
  .normal-enter-active,
  .normal-leave-active {
    transition: .5s;
  }
  .normal-enter-from,
  .normal-leave-to {
    opacity: 0;
  }
  .normal-enter-to,
  .normal-leave-from {
    opacity: 1;
  }
  .delay-enter-active {
    transition: 1s;
    transition-delay: 7.5s;
  }
  .delay-leave-active {
    transition: .5s;
  }
  .delay-enter-from,
  .delay-leave-to {
    opacity: 0;
  }
  .delay-enter-to,
  .delay-leave-from {
    opacity: 1;
  }

  @media screen and (max-width: 1024px) {
    .wrapper {
      width: calc( 100% - 20px );
      margin: 0 10px;
    }
    .title {
      margin-bottom: 15px;
      font-size: 1.25em;
    }
  }
</style>
