<template>
  <div class="promotion">
    <aos-vue animation="fade-right">
      <div class="title">
        最新優惠項目
      </div>
    </aos-vue>
    <aos-vue animation="fade-left">
      <div class="wrapper">
        <ul class="promotion_nav">
          <li
            v-for="(item, idx) in list"
            :key="idx"
            @click="changeList(idx)"
            :class="{ active: active === idx }"
          >
            {{ item.text }}
          </li>
        </ul>
        <div v-if="active === 0" class="promotion_content">
          <div class="promotion_table">
            <div class="row head">
              <div class="cell"></div>
              <div class="cell">一年期</div>
              <div class="cell">三年期</div>
            </div>
            <div class="row">
              <div class="cell">專屬企業網站</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">雲端虛擬展間 or web story</div>
              <div class="cell">僅雲端虛擬展間</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">第三方國際企業認證</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">生態圈服務</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">台灣產品數位電子雜誌廣告1/4頁</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">經貿透視雙週刊電子版</div>
              <div class="cell"><img src="../assets/check.svg" /></div>
              <div class="cell"><img src="../assets/check.svg" /></div>
            </div>
            <div class="row">
              <div class="cell">贈送Google關鍵字廣告</div>
              <div class="cell price">2000元</div>
              <div class="cell price">4000元</div>
            </div>
          </div>
        </div>
        <div v-if="active === 1" class="promotion_content">
          <div class="section">
            <a href="https://info.taiwantrade.com/register/supplier" target="_blank" class="head">新加入會員</a>
              <div class="section_row">
                <img class="promotion_02_img" src="../assets/promotion_02.png" />
                <ul>
                  <li>10/31前加入贈限量好禮松木電火鍋</li>
                  <li>數據懶人包</li>
                </ul>
              </div>
          </div>
          <div class="section">
            <a href="https://info.taiwantrade.com/register/supplier" target="_blank" class="head">續約會員</a>
            <ul>
              <li>神秘好禮</li>
              <li>另可加購數據懶人包</li>
            </ul>
          </div>
        </div>
        <div v-if="active === 2" class="promotion_content">
          <div class="section">
            <a href="https://info.taiwantrade.com/register/supplier" target="_blank" class="head">10月</a>
            <p>Featured Products 廣告一週 - 前25名</p>
            <img src="../assets/promotion_03.png" />
          </div>
        </div>
      </div>
    </aos-vue>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { text: '尊爵會員基本服務' },
        { text: '年終入會精選好禮' },
        { text: '年終入會限時限量服務' }
      ],
      active: 0
    }
  },
  methods: {
    changeList (idx) {
      this.active = idx
    }
  }
}
</script>

<style lang="scss">
  .promotion {
    padding: 50px 0;
    background: var(--lightPurple);
    .title {
      position: relative;
      margin-bottom: 50px;
      text-align: center;
      color: white;
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: calc( 50% - 25px );
        width: 50px;
        height: 7.5px;
        border-radius: 10px;
        background: var(--yellow);
      }
    }
    &_nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin: 0;
      padding: 0 25px;
      li {
        padding: 15px 25px;
        margin-right: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 1.25em;
        color: white;
        background: #598AD1;
        cursor: pointer;
        transition: .2s;
        &.active {
          font-weight: 700;
          color: var(--yellow);
          background: var(--deepPurple);
        }
      }
    }
    &_content {
      display: flex;
      flex-flow: row nowrap;
      padding: 50px 100px;
      border-radius: 10px;
      background: var(--deepPurple)
    }
    &_table {
      flex: 1;
      display: table;
      width: 100%;
      .row {
        display: table-row;
        .cell {
          display: table-cell;
          padding: 5px 0;
          text-align: center;
          color: white;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            color: var(--yellow);
          }
          &:nth-child(2) {
            img {
              filter: grayscale(1);
            }
          }
          img {
            width: 25px;
          }
        }
      }
      .head {
        font-size: 1.25em;
        font-weight: 700;
      }
    }
    .section {
      flex: 1;
      padding: 25px;
      border-radius: 10px;
      background: white;
      .head {
        position: relative;
        top: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 25px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        border-radius: 10px;
        color: white;
        background: var(--red);
        transition: .2s;
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background: url('../assets/check.svg') center no-repeat;
          background-size: contain;
        }
        &:hover {
          top: -3px;
        }
      }
      & + .section {
        margin-left: 15px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          margin-left: 25px;
          line-height: 30px;
          font-size: 1.05em;
          font-weight: 700;
          list-style-type: disc;
        }
      }
      img {
        max-width: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .section_row {
        display: flex;
        flex-flow: row nowrap;
      }
      p {
        text-align: center;
        font-size: 1.05em;
        font-weight: 700;
      }
    }
    &_02_img {
      width: 100px;
      margin-right: 25px;
    }
  }
  .price {
    font-size: 1.25em;
    font-weight: 700;
  }
  @media screen and (max-width: 1024px) {
    .promotion {
      padding: 25px 0;
      &_nav {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 0;
        li {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          border-radius: 10px;
          text-align: center;
        }
      }
      &_content {
        flex-flow: column nowrap;
        padding: 15px;
      }
      &_table {
        .row {
          .cell {
            padding: 2.5px;
            font-size: .75em;
            img {
              width: 10px;
            }
          }
        }
        .head {
          font-size: 1em;
        }
      }
      .section {
        padding: 15px;
        .head {
          margin-bottom: 15px;
          &:before {
            width: 10px;
            height: 10px;
            margin-right: 5px;
          }
        }
        & + .section {
          margin-left: 0;
          margin-top: 10px;
        }
        ul, ul li {
          font-size: 0.75em;
        }
        p {
          font-size: 0.75em;
        }
      }
      &_02_img {
        margin-right: 15px;
      }
    }
  }
</style>
