<template>
  <div class="rule">
    <aos-vue animation="fade-down">
      <div class="rule_box">
        <div class="rule_section">
          <div class="rule_title">遊戲活動規則</div>
          <ol>
            <li>
              登入line並加入「台灣經貿網」官方帳號方可獲得抽獎資格（單一帳號限抽一次獎項）。
            </li>
            <li>
              請將中獎畫面截圖回傳「台灣經貿網」line官方帳號留下您的 ：中獎人本名、line上的姓名、 email 、手機號碼、公司名稱、統編，後續將有專人協助後續領獎程序。
            </li>
            <li>
              依機會中獎稅法規定：<br />
              獎項價值超過新台幣20,000元以上(含2萬元)，須負擔10%機會中獎稅。非中華民國國境內居住之國人(即在中華民國境內居住未達183天之本國人及外國人)不論得獎人所得之金額，須負擔20%所得稅。
            </li>
            <li>
              依所得稅法規定：<br />
              中獎贈品或獎金都算所得，全年所中獎獎品超過市價1,000元，年度報稅時必須計入公司所得。
            </li>
            <li>
              得獎廠商：<br />
              限我國境內有固定營業場所的營利事業領取，且為台灣經貿網企業網頁會員。中獎廠商如於2021/11/4-2022/12/31期間到期退訂台灣經貿網會員權益，得獎獎品需退還予台灣經貿網。
            </li>
          </ol>
        </div>
        <div class="rule_section">
          <div class="rule_title">獎品領取說明</div>
          <p>
            上述各項贈品提供予參與活動廠商規範如下：
          </p>
          <ol>
            <li>
              領獎者皆須為台灣經貿網付費尊爵會員(EP)，贈品不予折現。
            </li>
            <li>
              一家公司一項贈品: 若同公司超過一人中獎，且獎項不同時，由公司代表挑選需要的贈品後，回傳蓋上大小章領獎同意書即可領獎。
            </li>
            <li>
              獲獎廠商最晚請於110年11月30日前兌換，否則視同自動放棄領獎資格。
            </li>
            <li>
              主辦單位保有最終修改、變更、活動解釋及取消本活動之權利，若有相關異動將會公告於網站，恕不另行通知。
            </li>
          </ol>
        </div>
        <img class="close" @click="closeRule" src="../assets/close.svg" />
      </div>
    </aos-vue>
  </div>
</template>

<script>
export default {
  methods: {
    closeRule () {
      this.$emit('close-rule')
    }
  }
}
</script>

<style lang="scss">
  .rule {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px;
    background: rgba(0,0,0,0.75);
    z-index: 9999;
    > div {
      height: 100%;
    }
    &_box {
      position: relative;
      width: 100%;
      max-width: 800px;
      padding: 50px;
      border-radius: 15px;
      color: white;
      background: var(--deepPurple);
    }
    &_section {
      line-height: 25px;
      ol {
        padding: 0 0 0 20px;
        margin: 10px 0 0;
        li {
          list-style-type: decimal;
        }
      }
      p {
        margin: 10px 0 0;
      }
    }
    &_section + &_section {
      margin-top: 25px;
    }
    &_title {
      font-size: 1.25em;
      font-weight: 700;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    .rule {
      display: block;
      padding: 15px;
      &_box {
        height: 100%;
        padding: 15px;
        border: 5px solid var(--deepPurple);
        overflow-y: scroll;
      }
    }
  }
</style>
