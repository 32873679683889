<template>
  <div class="sticky">
    <a target="_blank" href="#" class="sticky_button">預約<br />諮詢</a>
  </div>
</template>

<style lang="scss">
  .sticky {
    position: sticky;
    bottom: 15px;
    left: 0;
    display: flex;
    flex-flow: row-reverse nowrap;
    padding: 0 15px;
    margin-bottom: 15px;
    &_button {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      padding: 15px 0;
      border-radius: 50px;
      border: 5px solid white;
      color: white;
      font-size: 1.25em;
      font-weight: 700;
      line-height: 1.25em;
      text-align: center;
      text-decoration: none;
      background: var(--red);
    }
    z-index: 999;
  }

  @media screen and (max-width: 1024px) {
    .sticky {
      padding: 0;
      &_button {
        width: calc( 100% - 20px );
        height: 50px;
        margin: 0 10px;
        font-size: 1em;
        border: 2px solid white;
        br {
          display: none;
        }
      }
    }
  }
</style>
