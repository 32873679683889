<template>
  <div class="info">
    <img class="detail_curve" src="../assets/curve.svg" />
    <aos-vue animation="fade-right">
      <div class="detail wrapper">
        <div class="detail_content">
          <div class="title">
            當疫後新常態來臨  <br />要如何搶占市場商機
            </div>
          <p>
            新冠肺炎疫情影響各行各業，甚至改變商業模式，旅遊、餐飲業等面臨極大考驗，外送平台、電商等卻快速成長。宅經濟商機也逐漸崛起，由於無法外出的緣故，開始帶動零接觸消費經濟，網路購物的平均支出已增加了 10 – 30％，當數位轉型已經變成新常態，面對強勢的電商浪潮，臺灣廠商如何選擇與搶佔跨境新藍海市場，將是下一波數位貿易新關鍵。
          </p>
        </div>
        <div class="detail_img">
          <img class="feature_01" src="../assets/feature_01.svg" />
          <img class="feature_02" src="../assets/feature_02.svg" />
          <img class="feature_03" src="../assets/feature_03.svg" />
        </div>
      </div>
    </aos-vue>
    <div class="map wrapper">
      <img src="../assets/map.png" />
      <img class="line" src="../assets/map_line.svg" />
      <div class="map_content">
        <aos-vue animation="fade-up">
          <div class="map_content_box">
            <div class="title">
              面對浪潮的來襲<br />
              我們提供哪些服務
            </div>
            <ul class="map_feature">
              <li>提供國際認證</li>
              <li>新聞媒體投放</li>
              <li>協助營運代操</li>
              <li>策略夥伴對接</li>
              <li>推廣數位行銷</li>
              <li>線上金流服務</li>
              <li>專業數位培訓</li>
              <li>突破跨境物流</li>
              <li>共同產學合作</li>
              <li>接軌國際電商</li>
            </ul>
          </div>
        </aos-vue>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .info {
    position: relative;
    padding: 50px 0;
  }
  .detail {
    &_curve {
      position: absolute;
      bottom: 0;
      right: 25%;
      width: 1920px;
      transform: scaleX(-1);
      animation: position-bottom;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 50px;
    border-radius: 15px;
    background: var(--lightPurple);
    &:after {
      content: '';
      position: absolute;
      bottom: -25px;
      right: -75px;
      width: 25px;
      height: 25px;
      background: url('../assets/pattern_03.svg') center no-repeat;
      background-size: contain;
    }
    &_content {
      position: relative;
      width: calc( 100% - 350px );
      font-size: 1em;
      line-height: 1.75em;
      color: white;
      .title br {
        display: none;
      }
    }
    &_img {
      position: relative;
      width: 350px;
      height: 200px;
      > img {
        position: absolute;
        top: calc( 50% - 60.25px );
        left: calc( 50% - 60.25px );
        width: 125px;
      }
      .feature_01 {
        margin-top: -50px;
        margin-left: -50px;
        animation: flip infinite 5s;
      }
      .feature_02 {
        margin-top: -25px;
        margin-left: 75px;
        animation: flip infinite 5s;
        animation-delay: 1s;
      }
      .feature_03 {
        margin-top: 50px;
        animation: flip infinite 5s;
        animation-delay: 2s;
      }
    }
  }
  .map {
    position: relative;
    padding: 50px 0;
    > img {
      width: 100%;
    }
    .line {
      position: absolute;
      top: 50px;
      left: 0;
    }
    &_content {
      position: absolute;
      top: 50px;
      right: 0;
      width: 40%;
      &_box {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 100%;
        padding: 50px;
        border-radius: 10px;
        background: white;
      }
    }
    .title {
      position: relative;
      margin-bottom: 50px;
      text-align: center;
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: calc( 50% - 25px );
        width: 50px;
        height: 7.5px;
        border-radius: 10px;
        background: var(--yellow);
      }
    }
    &_feature {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: calc( 50% - 7.5px );
        padding: 15px 0;
        margin: 0 0 15px;
        text-align: center;
        font-weight: 900;
        color: white;
        border-radius: 50px;
        background: var(--red);
        &:before {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          margin-right: 10px;
          background: url('../assets/check.svg') center no-repeat;
          background-size: contain;
        }
      }
    }
  }
  @keyframes flip {
    0% {
      transform: scaleX(1);
    }
    5% {
      transform: scaleX(-1);
    }
    10% {
      transform: scaleX(1);
    }
  }
  @media screen and (max-width: 1024px) {
    .info {
      padding: 0 0 25px;
    }
    .detail {
      flex-flow: column;
      padding: 50px 25px;
      &_content {
        width: 100%;
        margin-bottom: 15px;
        .title {
          text-align: center;
          br {
            display: block;
          }
        }
      }
      &_img {
        width: 100%;
      }
    }
    .map {
      padding: 25px 0;
      .line {
        top: 25px;
      }
      &_content {
        position: static;
        width: 100%;
        &_box {
          padding: 50px 25px;
        }
      }
      &_feature {
        li {
          width: calc( 50% - 5px );
          padding: 10px 0;
          margin: 0 0 10px;
          &:before {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>
