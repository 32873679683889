<template>
  <div class="header">
    <a href="https://tw.taiwantrade.com/" target="_blank"><img src="../assets/logo.png" /></a>
    <ul class="main_menu">
      <li @click="scrollToTag('info')">前言</li>
      <li @click="scrollToTag('rotate')">幸運轉盤</li>
      <li @click="scrollToTag('promotion')">最新優惠項目</li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTag (className) {
      const target = document.getElementsByClassName(className)[0]
      target.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 35px;
    border-radius: 10px;
    background: white;
    z-index: 999;
    > a img {
      height: 50px;
    }
  }
  .main_menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    li {
      position: relative;
      margin-left: 25px;
      padding-bottom: 7.5px;
      font-size: 1em;
      line-height: 1.5em;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #D1D1D1;
        transition: .2s;
      }
      &:hover:after {
        background: var(--deepPurple);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .header {
      justify-content: center;
      padding: 7.5px 0;
      a > img {
        height: 35px;
      }
    }
    .main_menu {
      display: none;
    }
  }
</style>
