<template>
  <div class="award" :class="{ get: get }">
    <aos-vue animation="fade-up">
      <div class="award_box">
        <div class="award_head">
          恭喜獲得
        </div>
        <img :src="`/img/${award.award_id}.png`" />
        <div class="award_title">{{ award.name }}</div>
        <div class="award_subtitle">{{ award.sub_name }}</div>
        <div class="award_description">{{ award.descript }}</div>
        <div class="award_section">
          <div class="head">
            領獎流程：
          </div>
          <ol>
            <li>
              請將中獎畫面截圖回傳「台灣經貿網」line官方帳號留下您的 ：中獎人本名、line上的姓名、 email 、手機號碼、公司名稱、統編，後續將有專人協助後續領獎程序。
            </li>
            <li>
              獲獎廠商最晚請於110年11月30日前兌換，否則視同自動放棄領獎資格。
            </li>
            <li>
              主辦單位保有最終修改、變更、活動解釋及取消本活動之權利，若有相關異動將會公告於網站，恕不另行通知。
            </li>
          </ol>
        </div>
        <div class="check_button" @click="closeAward">已截圖完成!</div>
      </div>
    </aos-vue>
  </div>
</template>

<script>
export default {
  props: ['award', 'get'],
  methods: {
    closeAward () {
      this.$emit('close-award')
    }
  }
}
</script>

<style lang="scss">
  .award {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background: rgba(255,255,255,0.75);
    z-index: 9999;
    > div {
      height: 100%;
    }
    &_box {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 800px;
      padding: 25px 50px;
      border-radius: 15px;
      background: white;
      box-shadow: 0 5px 10px rgba(0,0,0,0.25);
    }
    &_head {
      font-size: 1.5em;
      font-weight: 700;
      color: var(--deepPurple);
      text-align: center;
    }
    img {
      height: 75px;
      margin-top: 25px;
    }
    &_title {
      margin-top: 25px;
      font-size: 1.75em;
      font-weight: 700;
    }
    &_subtitle {
      font-size: 1.25em;
      font-weight: 700;
    }
    &_description {
      margin-top: 25px;
      line-height: 25px;
    }
    &_section {
      line-height: 25px;
      margin-top: 25px;
      .head {
        color: var(--deepPurple);
      }
      ol {
        padding: 0 0 0 20px;
        margin: 10px 0 0;
        li {
          list-style-type: decimal;
        }
      }
      p {
        margin: 10px 0 0;
      }
    }
  }
  .get {
    transition-delay: 0 !important;
  }
  .check_button {
    position: relative;
    top: 0;
    width: 60%;
    padding: 10px 0;
    margin-top: 25px;
    border-radius: 50px;
    border: 5px solid white;
    color: white;
    font-size: 1.25em;
    font-weight: 700;
    text-align: center;
    background: var(--red);
    cursor: pointer;
    transition: .2s;
    &:hover {
      top: -5px;
    }
  }
  @media screen and (max-width: 1024px) {
    .award {
      display: block;
      padding: 15px;
      &_box {
        padding: 15px;
        border: 5px solid white;
        overflow-y: scroll;
      }
      img {
        height: 50px;
        margin-top: 15px;
      }
      &_title {
        margin-top: 15px;
        font-size: 1.5em;
        text-align: center;
      }
      &_subtitle {
        font-size: 1em;
      }
    }
  }
</style>
