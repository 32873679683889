<template>
  <div class="rotate">
    <img class="rotate_line" src="../assets/line.svg" />
    <div class="wrapper">
      <aos-vue animation="fade-right">
        <div class="title">
          參加台灣經貿網年度大會<br />
          專屬限時活動
        </div>
      </aos-vue>
      <aos-vue animation="fade-up">
        <div class="rotate_area">
          <img class="rotate_BG" :style="`transform: rotate(${deg}deg)`" src="../assets/rotate_BG.svg" />
          <img class="rotate_arrow" src="../assets/rotate_arrow.svg" />
          <img class="rotate_button" @click="openAward" src="../assets/rotate_button.svg" />
        </div>
      </aos-vue>
      <div class="rotate_rule">
        <div class="rotate_rule_button" @click="openRule">
          遊戲活動規則<img src="../assets/plus.svg" />
        </div>
        <div class="rotate_rule_alert">
          參與活動前，請詳閱遊戲規則
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['deg'],
  methods: {
    openRule () {
      this.$emit('open-rule')
    },
    openAward () {
      this.$emit('open-award')
    }
  }
}
</script>

<style lang="scss">
  .rotate {
    position: relative;
    background: var(--reverse-gradient);
    &_line {
      position: absolute;
      bottom: 0;
      left: 25%;
      width: 1920px;
      transform: rotate(180deg);
      margin-bottom: 0;
      animation: position-bottom;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    .title {
      position: relative;
      color: white;
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 50px;
        height: 7.5px;
        border-radius: 10px;
        background: var(--yellow);
      }
    }
    &_area {
      position: relative;
      width: 75%;
      margin: -25px 12.5%;
      &:before {
        content: '';
        position: absolute;
        bottom: 100px;
        left: -150px;
        width: 100px;
        height: 100px;
        background: url('../assets/pattern_01.svg') center no-repeat;
        background-size: contain;
        animation: scale infinite 2s;
        animation-delay: 1.5s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      &:after {
        content: '';
        position: absolute;
        top: 100px;
        right: -150px;
        width: 50px;
        height: 50px;
        background: url('../assets/pattern_02.svg') center no-repeat;
        background-size: contain;
        animation: scale infinite 2s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      // &:hover .rotate_BG {
      //   animation-play-state: paused;
      //   transform: rotate(0deg);
      // }
    }
    &_BG {
      width: 100%;
      transition: 7.5s;
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
      // animation: rotate;
      // animation-duration: 15s;
      // animation-iteration-count: infinite;
      // animation-timing-function: linear;
    }
    &_arrow {
      position: absolute;
      top: calc( 50% - 20px );
      left: calc( 50% - 150px );
      height: 40px;
    }
    &_button {
      position: absolute;
      top: calc( 50% - 80px );
      left: calc( 50% - 80px );
      height: 160px;
      cursor: pointer;
      transition: .3s;
      &:hover {
        transform: scale(1.1);
      }
    }
    &_rule {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      &_button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 10px;
        font-size: 1.5em;
        color: white;
        cursor: pointer;
        z-index: 99;
        img {
          height: 25px;
          margin-left: 5px;
        }
      }
      &_alert {
        color: var(--yellow);
      }
    }
  }

  // @keyframes rotate {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform:  rotate(-360deg);
  //   }
  // }

  @media screen and (max-width: 1024px) {
    .rotate {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .title {
        margin-bottom: 50px;
        text-align: center;
        &:after {
          left: calc( 50% - 25px );
        }
      }
      &_line {
        width: 1024px;
      }
      &_area {
        width: 100%;
        margin: 25px 0;
      }
      &_arrow {
        top: calc( 50% - 12.5px );
        left: calc( 50% - 75px );
        height: 20px;
      }
      &_button {
        top: calc( 50% - 40px );
        left: calc( 50% - 40px );
        height: 80px;
      }
      &_rule {
        align-items: center;
      }
    }
  }
</style>
