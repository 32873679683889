<template>
  <div class="banner">
    <div class="wrapper">
      <img class="banner_line" src="../assets/line.svg" />
      <img class="banner_curve" src="../assets/curve.svg" />
      <img class="banner_pattern_01" src="../assets/pattern_01.svg" />
      <img class="banner_pattern_02" src="../assets/pattern_02.svg" />
      <div class="banner_content">
        <Header></Header>
        <img class="banner_title" src="../assets/title_animated.svg"/>
        <img class="banner_sponsor" src="../assets/sponsor.png"/>
        <img class="banner_icon_01" src="../assets/icon_01.svg"/>
        <img class="banner_icon_02" src="../assets/icon_02.svg"/>
        <img class="banner_icon_03" src="../assets/icon_03.svg"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .banner {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 15px;
    background: var(--gradient);
    &_line {
      position: absolute;
      width: 1920px;
      top: 0;
      right: 25%;
      margin-top: 0;
      animation: position-top;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    &_curve {
      position: absolute;
      width: 1920px;
      bottom: -10%;
      left: 25%;
      margin-bottom: 0;
      animation: position-bottom;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    &_pattern_01 {
      position: absolute;
      width: 5%;
      top: 25%;
      right: 5%;
      animation: scale infinite 2s;
      animation-delay: 1.5s;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &_pattern_02 {
      position: absolute;
      width: 2.5%;
      bottom: 15%;
      left: 5%;
      animation: scale infinite 2s;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &_content {
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    &_title {
      position: relative;
      width: 80%;
      margin: 10% 0 0;
      transform: scale(1);
      animation: title;
      animation-delay: .5s;
      animation-duration: .5s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    &_sponsor {
      position: relative;
      width: 60%;
      margin: 0 0 10%;
      animation: title;
      animation-delay: .75s;
      animation-duration: .5s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-fill-mode: backwards;
    }
    &_icon_01 {
      position: absolute;
      width: 12.5%;
      top: 23.5%;
      right: 40%;
    }
    &_icon_02 {
      position: absolute;
      width: 8.5%;
      top: 47.5%;
      left: 1.5%;
    }
    &_icon_03 {
      position: absolute;
      width: 12.5%;
      top: 57.5%;
      right: 2.5%;
    }
  }
  @keyframes scale {
    0% {
      transform: scale(1)
    }
    50% {
      transform: scale(1.25)
    }
    100% {
      transform: scale(1)
    }
  }
  @keyframes title {
    0% {
      transform: scale(.5);
      top: -200px;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      top: 0px;
      opacity: 1;
    }
  }
  @keyframes position-bottom {
    0% {
      margin-bottom: 0;
    }
    50% {
      margin-bottom: -15px;
    }
    100% {
      margin-bottom: 0;
    }
  }
  @keyframes position-top {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: -25px;
    }
    100% {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    .banner {
      padding: 10px 0;
      &_line {
        width: 1024px;
      }
      &_curve {
        width: 1024px;
      }
      &_content {
        padding: 0;
      }
      &_icon_01 {
        top: 32.5%;
        right: 40%;
      }
      &_icon_02 {
        top: 53.5%;
        left: 1.5%;
      }
      &_icon_03 {
        top: 60%;
        right: 2.5%;
      }
    }
  }
</style>
<script>
import Header from './Header.vue'
export default {
  components: {
    Header
  }
}
</script>
